import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

const COOKIES_CONSENTED = 'user-consented-to-cookies';
const COOKIE_FACEBOOK_PIXEL = 'user-consented-to-facebook-pixel';
const COOKIE_GOOGLE_TAG_MANAGER = 'user-consented-to-google-tagmanager';

const useCookiesConsent = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    COOKIES_CONSENTED,
    COOKIE_FACEBOOK_PIXEL,
    COOKIE_GOOGLE_TAG_MANAGER,
  ]);

  const hasUserConsentedToCookies =
    typeof cookies[COOKIES_CONSENTED] !== `undefined`
      ? cookies[COOKIES_CONSENTED]
      : false;

  const setCookiesConsented = () => {
    setCookie(COOKIES_CONSENTED, true, { path: '/' });
  };

  const setFacebookConsented = () => {
    setCookie(COOKIE_FACEBOOK_PIXEL, true, { path: '/' });
  };

  const setGoogleTagManagerConsented = () => {
    setCookie(COOKIE_GOOGLE_TAG_MANAGER, true, { path: '/' });
  };

  useEffect(() => {
    return () => {};
  }, []);

  return {
    hasUserConsentedToCookies,
    setCookiesConsented,
    setFacebookConsented,
    setGoogleTagManagerConsented,
  };
};

export default useCookiesConsent;
