import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

interface SeoProps {
  title: string;
  description?: string;
  meta?: any;
}

export const query = graphql`
  query getSiteMetadata {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        keywords
      }
    }
  }
`;

const Seo = ({ title, description, meta }: SeoProps) => {
  const { site } = useStaticQuery(query);
  // console.log(`site`, site);

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const image =
    'https://magic.audio/assets/website-social/website.jpg?' +
    new Date().getTime();

  // console.log(`SEO - metaTitle:`, metaTitle);
  // console.log(`metaDescription`, metaDescription);
  // console.log(`SEO - image:`, image);

  return (
    <Helmet defer={false} titleTemplate='%s | Magic.Audio'>
      <html lang='en' />
      <title>{metaTitle}</title>
      {/* <title itemProp='name' lang='en'>
        {metaTitle}
      </title> */}
      <meta name='description' content={metaDescription} />
      <meta name='keywords' content={site.siteMetadata.keywords} />
      <meta name='author' content={site.siteMetadata.author} />

      <meta name='og:url' content={site.siteMetadata.siteUrl} />
      <meta name='og:title' content={metaTitle} />
      <meta name='og:description' content={metaDescription} />
      <meta name='og:type' content={`website`} />
      <meta name='og:image' content={image} />
      <meta name='og:url' content={site.siteMetadata.siteUrl} />

      <meta name='twitter:card' content={`summary`} />
      <meta name='twitter:creator' content={site.siteMetadata.author} />
      <meta name='twitter:image' content={image} />
      <meta name='twitter:title' content={metaTitle} />
      <meta name='twitter:description' content={metaDescription} />
    </Helmet>
  );

  // return (
  //   <Helmet
  //     htmlAttributes={{ lang: `en` }}
  //     title={metaTitle}
  //     meta={[
  //       {
  //         name: `description`,
  //         content: metaDescription,
  //       },
  //       {
  //         name: `keywords`,
  //         content: site.siteMetadata.keywords,
  //       },
  //       {
  //         name: `viewport`,
  //         content: `initial-scale=1, width=device-width`,
  //       },
  //       {
  //         name: `author`,
  //         content: site.siteMetadata.author,
  //       },
  //       {
  //         property: `og:url`,
  //         content: site.siteMetadata.siteUrl,
  //       },
  //       {
  //         property: `og:title`,
  //         content: metaTitle,
  //       },
  //       {
  //         property: `og:description`,
  //         content: metaDescription,
  //       },
  //       {
  //         property: `og:type`,
  //         content: `website`,
  //       },
  //       {
  //         property: `og:image`,
  //         content: `/website-social/website.jpg`,
  //       },
  //       {
  //         name: `twitter:card`,
  //         content: `summary`,
  //       },
  //       {
  //         name: `twitter:creator`,
  //         content: `twitter:creator`, //site.siteMetadata?.social?.twitter || ``,
  //       },
  //       {
  //         name: `twitter:title`,
  //         content: metaTitle,
  //       },
  //       {
  //         name: `twitter:description`,
  //         content: metaDescription,
  //       },
  //     ].concat(meta ? meta : [])}
  //   >
  //     <link rel='canonical' href='https://magic.audio/' />
  //   </Helmet>
  // );
};

export default Seo;
