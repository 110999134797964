import React from 'react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
// import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';

import useCookiesConsent from '../../../hooks/useCookiesConsent';

// import CookiesConsentDialog from '../../components/CookiesConsentDialog';
import CookiesConsentBar from '../../components/CookiesConsentBar';

import Header from '../common/Header';
import Footer from '../common/Footer';

const drawerWidth = 240;

interface DefaultLayoutProps {
  children: React.ReactNode;
  window?: any;
}

function DefaultLayout({ children, window }: DefaultLayoutProps) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [cookiesConsentDialogOpen, setCookiesConsentDialogOpen] =
    React.useState(false);
  const { hasUserConsentedToCookies } = useCookiesConsent();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component='div'
      sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}
    >
      <Header />
      <Box component='main' sx={{ flexGrow: 1 }}>
        <Toolbar />
        {children}
      </Box>
      <Footer />
      {/* <CookiesConsentDialog open={cookiesConsentDialogOpen} /> */}
      {/* {!hasUserConsentedToCookies && <CookiesConsentBar />} */}
    </Box>
  );

  // return (
  //   <Box sx={{ display: 'flex' }}>
  //     <AppBar
  //       position='fixed'
  //       sx={{
  //         width: { sm: `calc(100% - ${drawerWidth}px)` },
  //         ml: { sm: `${drawerWidth}px` },
  //       }}
  //     >
  //       <Toolbar>
  //         <IconButton
  //           color='inherit'
  //           aria-label='open drawer'
  //           edge='start'
  //           onClick={handleDrawerToggle}
  //           sx={{ mr: 2, display: { sm: 'none' } }}
  //         >
  //           <MenuIcon />
  //         </IconButton>
  //         <Typography variant='h6' noWrap component='div'>
  //           Responsive drawer
  //         </Typography>
  //       </Toolbar>
  //     </AppBar>
  //     <Box
  //       component='nav'
  //       sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
  //       aria-label='mailbox folders'
  //     >
  //       {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
  //       <Drawer
  //         container={container}
  //         variant='temporary'
  //         open={mobileOpen}
  //         onClose={handleDrawerToggle}
  //         ModalProps={{
  //           keepMounted: true, // Better open performance on mobile.
  //         }}
  //         sx={{
  //           display: { xs: 'block', sm: 'none' },
  //           '& .MuiDrawer-paper': {
  //             boxSizing: 'border-box',
  //             width: drawerWidth,
  //           },
  //         }}
  //       >
  //         {drawer}
  //       </Drawer>
  //       <Drawer
  //         variant='permanent'
  //         sx={{
  //           display: { xs: 'none', sm: 'block' },
  //           '& .MuiDrawer-paper': {
  //             boxSizing: 'border-box',
  //             width: drawerWidth,
  //           },
  //         }}
  //         open
  //       >
  //         {drawer}
  //       </Drawer>
  //     </Box>
  //     <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
  //       <Toolbar />
  //       {children}
  //     </Box>
  //   </Box>
  // );
}

export default DefaultLayout;
